import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #FF4E00;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

export const Text = styled.div`
  color: #ffffff;
  font-size: 14px;
`;

export const Button = styled.button`
  background-color: #2C363F;
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: 15px;
  color: #fff;
  padding: 10px 16px;
  border-radius: 24px;
  border: 1px solid #2C363F;
  transition: 300ms ease-out;
  :hover {
    background: transparent;
  }
`;
